import { serverFetchRelatedPC } from "@/app/serverFunctionsCentra";
import { ProductCard } from "@/components/atoms";
import { useCart } from "@/lib/hooks/useSelection";
import { useQuery } from "@tanstack/react-query";
import { A11y, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./cart-upsell.module.css";

const useUpsell = () => {
  const { items } = useCart();
  const relatedIds = items.map((item) => item.productId);

  const { data } = useQuery({
    queryKey: ["upsellProducts", relatedIds],

    queryFn: () =>
      serverFetchRelatedPC(relatedIds, "accessory")
  });
  return { items: data };
};

const CartItems = ({ title }: { title: string }) => {
  const { items } = useUpsell();
  if (!items || items.length < 1) {
    return null;
  }
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <Swiper
        spaceBetween={16}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true }}
        navigation
        modules={[Navigation, A11y]}
      >
        {items?.map((item) => (
          <SwiperSlide className={styles.slide} key={"upsale" + item?.id}>
            <ProductCard product={item} related />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CartItems;
