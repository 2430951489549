"use client";

import { useSelection } from "@/lib/hooks/useSelection";
import getLink from "@/lib/storyblok/utils/getLink";
import {
  SnackbarMessageStoryblok,
  SnackbarMessageTextStoryblok,
  SnackbarStoryblok,
} from "@/types/storyblok-blok-types";
import clsx from "clsx";
import Image from "next/image";
import { default as Link } from "next/link";
import { useEffect, useState } from "react";
import styles from "./navbar.module.css";

interface ISnackbar {
  blok?: SnackbarStoryblok & Record<string, any>;
}

interface ISnackbarItem {
  messageItem?: SnackbarMessageStoryblok;
  active: boolean;
  prev?: boolean;
}

// Helper function to handle message content based on logged in or not
function messageStrings(
  messageItem: SnackbarMessageStoryblok,
  loggedIn: boolean,
) {
  let messages = messageItem.text;
  let text = messageItem.default;
  let link = messageItem.link;
  let displayText = messageItem.displayMessageItem;
  let icon = messageItem.icon?.filename;
  if (loggedIn) {
    messages = messageItem.textLoggedin || messageItem.text;
    text = messageItem.defaultLoggedIn || messageItem.default;
    link = messageItem.linkLoggedIn || messageItem.link;
    icon = messageItem.iconLoggedIn?.filename || messageItem.icon?.filename;
  }
  return {
    messages,
    text,
    link,
    displayText,
    icon,
  };
}

// Helper function to get message items who has market === country
function formatMessages(
  messages: SnackbarMessageStoryblok[],
  country: string,
  loggedIn: boolean,
) {
  if (!country) return [];
  const filterdMessages = messages.filter((messageItem) => {
    const { messages, text, displayText } = messageStrings(
      messageItem,
      loggedIn,
    );
    if (!displayText) return false;
    const message = messages?.filter((textItem) =>
      textItem?.market?.toUpperCase()?.split(",").includes(country),
    );

    if (!text && !message?.[0]?.text) {
      return false;
    }
    return true;
  });

  return filterdMessages;
}

const SnackbarUI = ({ blok }: ISnackbar) => {
  const { country, loggedIn } = useSelection();
  const [active, setActive] = useState(0);
  let doubledMessages: SnackbarMessageStoryblok[] = [];

  useEffect(() => {
    const timer = setInterval(() => {
      if (doubledMessages?.length) {
        setActive((prev) =>
          prev === doubledMessages?.length - 1 ? 0 : prev + 1,
        );
      }
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [doubledMessages]);

  if (!blok) return null;
  const { messages } = blok;
  if (!messages || !country) return null;

  const formattedMessages = formatMessages(messages, country, !!loggedIn);
  doubledMessages = formattedMessages?.concat(formattedMessages);

  if (!formattedMessages || !doubledMessages.length) return null;
  if (formattedMessages.length === 1) {
    return (
      <div id="snackbarOuter" className={styles.snackbarOuter}>
        <SnackbarItem messageItem={formattedMessages[0]} active={true} />
      </div>
    );
  }
  return (
    <div id="snackbarOuter" className={styles.snackbarOuter}>
      {doubledMessages?.map((messageItem, key) => {
        return (
          <SnackbarItem
            key={key}
            messageItem={messageItem}
            active={active === key}
            prev={
              active === 0
                ? key === doubledMessages.length - 1
                : active - 1 === key
            }
          />
        );
      })}
    </div>
  );
};

// Each message item rotating inside Snackbar
const SnackbarItem = ({ messageItem, active, prev }: ISnackbarItem) => {
  const { country, loggedIn } = useSelection();
  if (!messageItem || !country) return null;

  const { messages, text, link, icon } = messageStrings(
    messageItem,
    !!loggedIn,
  );

  const snackbarMessage = (
    message:
      | SnackbarMessageTextStoryblok
      | { link: undefined; text: undefined; market: undefined },
  ) => {
    if (link?.cached_url || message?.link?.cached_url) {
      const linkObj = getLink(message?.link?.cached_url ? message?.link : link);
      return (
        <Link href={linkObj}>
          {message?.market?.includes(country) && message?.text
            ? message?.text
            : text}
        </Link>
      );
    }
    return (
      <span>
        {message?.market?.includes(country) && message?.text
          ? message?.text
          : text}
      </span>
    );
  };

  return (
    <div
      className={clsx(
        styles.snackbarItem,
        active && styles.active,
        prev && styles.prev,
      )}
    >
      {icon && <Image src={icon} alt="" width={24} height={24} />}
      {snackbarMessage(
        messages
          ? messages[0]
          : { link: undefined, text: undefined, market: undefined },
      )}
    </div>
  );
};

export default SnackbarUI;
